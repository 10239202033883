import React from 'react'
import PropTypes from 'prop-types'
import Content from './ContentLegacy'
import Layout from "./layout"
import Header from "./header"
import Footer from "./footer"
import SEO from "./seo"
import { Link } from 'gatsby'
import Image from 'gatsby-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLanguage } from '@fortawesome/free-solid-svg-icons'


const BasicL10nPageTemplate = ({ title, content, contentComponent, asideImages, lang, url }) => {
  const PageContent = contentComponent || Content



  const getLabel = langKey => {
  switch (langKey) {
      case 'en': return 'English';
      case 'es': return 'Español';
      default: return null;
    }
  };
  const langLinks = lang.map( langS =>
      <Link to={`${langS.link}`} className={`button ${langS.selected ? 'is-primary':''} mr-4`}>{getLabel(langS.langKey)}</Link>
    )
  console.log(lang)
  return (
  <>
      <SEO title="Board of Directors" />
      <div className="content-section">
            <div class="mb-8">
              {langLinks}
            </div>
            <hr />
            <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
              {title}
            </h2>
            <PageContent className="content" content={content} />
      </div>
    </>
  )
}

BasicL10nPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

export default BasicL10nPageTemplate
